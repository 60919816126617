import React from 'react'
import './Loading.css'

function Loading() {
	return (
		<div className={'modal is-active'}>
			<div className="modal-background" />
			<div className="modal-content has-text-centered modal-load">
				<div className="column">
					<div className="lds-roller">
						<div /><div /><div /><div /><div /><div /><div /><div />
					</div>
					<h1 className="has-text-white title">Loading...</h1>
				</div>
			</div>
		</div>
	)
}

export default Loading
