import jwt from 'jsonwebtoken'
import axios from 'axios'
import ObjectHelpers from './ObjectHelpers'
let tokenKey = 'tokenpublic'

function removeCurrentToken() {
	localStorage.removeItem(tokenKey)
	axios.defaults.headers.common[tokenKey] = null
}

function setCurrentToken(token) {
	localStorage.setItem(tokenKey, token)
	axios.defaults.headers.common[tokenKey] = localStorage.getItem(tokenKey)
	return getCurrentUser()
}

function getCurrentUser() {
	const token = localStorage.getItem(tokenKey)
	const user = ObjectHelpers.getValueByKey(jwt.decode(token), 'user', null)
	return user
}

function redirectUser(user) {
	let location = ObjectHelpers.getValueByKey(user, 'id', null)
	if (location) {
		window.location = '/'
	} else {
		window.location = '/login'
		return false
	}
	return true
}

function isLogin() {
	return Boolean(getCurrentUser())
}

export default {
	isLogin,
	getCurrentUser,
	setCurrentToken,
	redirectUser,
	removeCurrentToken,
}
