import swal from 'sweetalert2'
import JwtHelpers from './JwtHelpers'
import _ from 'lodash'
import TranslateHelpers from './TranslateHelpers'
import Dialogs from './Dialogs'

export function handleGlobalError(error, curConfigs) {
	console.log(error)
	if (curConfigs.onError !== undefined && curConfigs.onError !== null) {
		if (curConfigs.onError(error)) {
			return
		}
	}
	if (error.response) {
		if (
			error.response.status === 403 &&
			error.response.data.message === 'Failed to authenticate token.'
		) {
			JwtHelpers.removeCurrentToken()
			JwtHelpers.redirectUser()
		} else if (
			error.response.status === 403 &&
			error.response.data.message ===
				'Password telah diubah, silahkan login kembali.'
		) {
			JwtHelpers.removeCurrentToken()
			swal('Oops...', error.response.data.message, 'error').then(() => {
				JwtHelpers.redirectUser()
			})
		} else if (
			error.response.status === 403 &&
			error.response.data.message ===
				'Illegal Access, User tidak ditemukan. Silahkan login kembali.'
		) {
			JwtHelpers.removeCurrentToken()
			JwtHelpers.redirectUser()
			swal('Oops...', error.response.data.message, 'error')
		} else if (
			error.response.status === 400 &&
			error.response.data.message === 'Token expired.'
		) {
			JwtHelpers.removeCurrentToken()
			JwtHelpers.redirectUser()
		} else if (
			error.response.status === 400 &&
			error.response.data.message === 'Role expired.'
		) {
			JwtHelpers.setCurrentToken(error.response.data.token)
			window.location.reload()
		} else {
			let translationKey = TranslateHelpers.getTranslation(
				error.response.data.message,
			)
			if (translationKey) {
				swal('Oops...', translationKey, 'error')
			} else {
				Dialogs.showError(
					'Error',
					_.get(error, 'response.data.message', error.message),
				)
			}
		}
	} else {
		swal('Oops...', _.get(error, 'message', 'No Internet Connection'), 'error')
	}
}

function defaultError(apiCall, configs) {
	let curConfigs = Object.assign({}, configs)
	return apiCall.catch(error => {
		handleGlobalError(error, curConfigs)
	})
}

export default {
	defaultError,
}
