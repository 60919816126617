import React from 'react'
import ReactDOM from 'react-dom'
import AppNew from './AppNew'
import './index.css'
import * as serviceWorker from './serviceWorker'
import { disableReactDevTools } from '@fvilers/disable-react-devtools'

if (process.env.REACT_APP_BUILD_ENV === 'production') {
	disableReactDevTools()
}

ReactDOM.render(<AppNew />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
