import axios from 'axios'
import { BASE_URL } from '../constants'
import Requests from '../utils/Requests'
import Calls from '../utils/Calls'
import JwtHelpers from '../utils/JwtHelpers'

axios.defaults.baseURL = BASE_URL
axios.defaults.headers.common.tokenpublic = localStorage.getItem('tokenpublic')
axios.interceptors.response.use(
	res => res,
	err => {
		if (err.response && err.response.status === 401) {
			let token = JwtHelpers.getCurrentUser()
			JwtHelpers.removeCurrentToken()
			if (token) {
				window.location.reload()
			}
		}
		throw err
	},
)

function FetchAll(component, api, stateName, extParam) {
	let params = 'page=0&pageSize=999999' + extParam
	const paramEncoded = encodeURI(params)
	component.setState({
		[stateName]: null,
	})
	Calls.defaultError(
		api(paramEncoded).then(res => {
			component.setState({
				[stateName]: res.data.data || [],
			})
		}),
		{
			onError: () => {
				component.setState({
					[stateName]: undefined,
				})
			},
		},
	)
}

//Login
function postLogin(data) {
	return axios.post('/athlete/login', data)
}

function updateProfile(data) {
	return axios.put('/public/profile', data)
}

function getAthleteById(AthleteId) {
	return axios.get(`/public/athlete/${AthleteId}`)
}

function getAthleteByBeyondId(BeyondId) {
	return axios.get(`/public/athlete/beyondid/${BeyondId}`)
}

// My Certificate
function getMyCertificate(paramEncoded) {
	return axios.get(`/public/certificate-athlete?${paramEncoded}`)
}

// Performance
function getPerformance(paramEncoded) {
	return axios.get(`/public/performance?${paramEncoded}`)
}

// Photo Event
function getPhotoEventByEventId(EventId) {
	return axios.get(`/public/photo-event/${EventId}`)
}

// MY Account Photo
function getMyPhotoEvent() {
	return axios.get(`/public/photo-event`)
}

function deleteMyPhotoEvent(PhotoEventId) {
	return axios.delete(`/public/photo-event`, { data: { id: PhotoEventId } })
}

function search(paramEncoded) {
	return axios.get(`/search?${paramEncoded}`)
}

function getEvent(paramEncoded) {
	return axios.get(`/event?${paramEncoded}`)
}

function postParticipateEvent(referral) {
	return axios.post(`/public/event/participate/${referral}`)
}

function getBlog(paramEncoded) {
	return axios.get(`/blog?${paramEncoded}`)
}

function getBlogById(BlogId) {
	return axios.get(`/blog/${BlogId}`)
}

function getEventById(EventId) {
	return axios.get(`/event/${EventId}`)
}

function getEventPhoto(paramEncoded) {
	return axios.get(`/event-photos?${paramEncoded}`)
}

function getEventDetail(paramEncoded) {
	return axios.get(`/event/:EventId/?${paramEncoded}`)
}

//Master Shirt Size
function getMasterShirtSize(paramEncoded) {
	return axios.get(`/master-shirt-size?${paramEncoded}`)
}

//Master Country
function getMasterCountry(paramEncoded) {
	return axios.get(`/master-country?${paramEncoded}`)
}

//City
function getCity(paramEncoded) {
	return axios.get(`/city?${paramEncoded}`)
}
//Master Gender
function getMasterGender(paramEncoded) {
	return axios.get(`/master-gender?${paramEncoded}`)
}

//Master Blood
function getMasterBlood(paramEncoded) {
	return axios.get(`/master-blood?${paramEncoded}`)
}

//Master Identity Card
function getMasterIdentityCard(paramEncoded) {
	return axios.get(`/master-identity-card?${paramEncoded}`)
}

function createPayment(data) {
	let formData = Requests.createFormData(data, [
		'EventId',
		{ key: 'listParticipant', type: 'array' },
		{ key: 'images', type: 'files' },
		{ key: 'timeResults', type: 'files' },
		'convenienceFee',
		'kodeVoucher',
		'verified',
	])
	return axios.post(`/public/payment`, formData)
}

function updatePayment(data) {
	let formData = Requests.createFormData(data, ['id', 'proof'])
	return axios.put(`/mandehbri/payment`, formData)
}

function updateAthlete(data) {
	let formData = Requests.createFormData(data, [
		'id',
		'username',
		'fullname',
		'email',
		'telp',
		'noTelpDarurat',
		'namaTelpDarurat',
		'alamat',
		'MasterBloodId',
		'MasterGenderId',
		'MasterShirtSizeId',
		'oldPassword',
		'newPassword',
		'confirmNewPassword',
	])
	return axios.put(`/mandehbri/athlete`, formData)
}

function createAthlete(data, query) {
	let formData = Requests.createFormData(data, [
		'username',
		'fullname',
		'email',
		'telp',
		'image',
		'faceMd5',
		'MasterBloodId',
		'MasterGenderId',
		'MasterShirtSizeId',
		'namaTelpDarurat',
		'noTelpDarurat',
		'newPassword',
		'confirmNewPassword',
		'noIdentitas',
		'MasterIdentityCardId',
		'tempatLahir',
		'tglLahir',
		'alamat',
		'MasterCountryId',
	])
	return axios.post(`/public/athlete?${query}`, formData)
}

function notificationMidtrans(data) {
	let formData = Requests.createObjectData(data, ['order_id'])
	return axios.post(`/midtrans/transaction/notification`, formData)
}

function getPayment(paramEncoded) {
	return axios.get(`/mandehbri/payment?${paramEncoded}`)
}

function getPaymentByCodeTransaction(codeTransaction) {
	return axios.get(`/mandehbri/payment/${codeTransaction}`)
}

//VOUCHER
function checkVoucher(data) {
	let formData = Requests.createObjectData(data, [
		'EventId',
		'listParticipant',
		'kodeVoucher',
	])
	return axios.post(`/check-voucher`, formData)
}

function redeemVoucher(data) {
	let formData = Requests.createObjectData(data, ['EventId', 'kodeVoucher'])
	return axios.post(`/check-voucher/redeem`, formData)
}

function generateTicketPayment(PaymentId) {
	return axios.get(`/mandehbri/payment/generate-pdf/${PaymentId}`)
}

function postResetPassword(email) {
	return axios.post(`/public/token/reset-password/${email}`)
}

function postNewPassword(token, formData) {
	return axios.post(`/public/token/new-password/${token}`, formData)
}

//CERTIFICATE ATHLETE
function postCertificateAthlete(data) {
	let formData = Requests.createObjectData(data, [
		'fileName',
		'centerX',
		'centerY',
		'PhotoEventId',
		'RacepakId',
	])
	return axios.post('/public/certificate-athlete', formData)
}

function addToGalleryAthlete(EventPhotoId) {
	return axios.post(`/event-photos/add-to-gallery-athlete/${EventPhotoId}`)
}

function getContentPageByCodePage(CodePage) {
	return axios.get(`/content-page/content/${CodePage}`)
}

//VirtualSport

function GetDataLink(data) {
	return axios.post(`/get-data`, data)
}

const ApiCall = {
	getContentPageByCodePage,
	addToGalleryAthlete,
	getPerformance,
	getPhotoEventByEventId,
	getMyCertificate,
	postCertificateAthlete,
	postResetPassword,
	postNewPassword,
	notificationMidtrans,
	checkVoucher,
	getPaymentByCodeTransaction,
	getMasterGender,
	getCity,
	getMasterCountry,
	getMasterBlood,
	getMasterIdentityCard,
	getPayment,
	createAthlete,
	updateAthlete,
	updateProfile,
	updatePayment,
	createPayment,
	getAthleteByBeyondId,
	getMasterShirtSize,
	postLogin,
	getEventPhoto,
	getEvent,
	getBlog,
	getBlogById,
	getEventById,
	search,
	getAthleteById,
	generateTicketPayment,
	FetchAll,
	getMyPhotoEvent,
	deleteMyPhotoEvent,
	postParticipateEvent,
	redeemVoucher,
	GetDataLink,
	axios,
}

export default ApiCall
