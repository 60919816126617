const url = {
	production:
		process.env.REACT_APP_BASE_API_OLD || 'https://api.beyondrun.com/api/v1',
	// staging: 'http://139.59.243.28:4000/api/v1',
	development: 'http://localhost:4000/api/v1',
	// development: 'http://139.59.243.28:4000/api/v1',
	// development: 'https://api.beyondrun.com/api/v1',
	staging: 'https://api2.beyondrun.com/api/v1',
	stagingNew: process.env.REACT_APP_BASE_API_OLD,
	// development: 'https://api-beyondrun.nusantech.co/api/v1',
}

const shareUrl = {
	production:
		process.env.BASE_SHARE_URL || 'https://share.beyondrun.com',
	development: 'http://localhost:5555',
	staging: process.env.BASE_SHARE_URL || 'https://share2.beyondrun.com',
	stagingNew: process.env.BASE_SHARE_URL || 'https://share2.beyondrun.com',
}

// const HEROKU_VR = {
// 	production: 'https://beyond-run-be.herokuapp.com',
// 	staging: 'https://beyond-run-be.herokuapp.com',
// 	development: 'https://beyond-run-be.herokuapp.com',
// }

const API_VR = {
	production:
		process.env.REACT_APP_BASE_API || 'https://api-prod-new.beyondrun.com/v1',
	staging: 'https://api-prod-new2.beyondrun.com/v1',
	development: 'http://localhost:5000/v1',
	stagingNew: process.env.REACT_APP_BASE_API,
}

const urlPublic = {
	production: process.env.REACT_APP_BASE_HOME_PAGE || 'https://beyondrun.com',
	staging: 'http://beyondrun.nusantech.co',
	development: 'http://localhost:3000',
	stagingNew: process.env.REACT_APP_BASE_HOME_PAGE,
}

const urlPublicNew = {
	production: process.env.REACT_APP_NEXT_HOME_PAGE || 'https://beyondrun.com',
	staging: process.env.REACT_APP_NEXT_HOME_PAGE || 'https://next2.beyondrun.com',
	development: 'http://localhost:3333',
	stagingNew:
		process.env.REACT_APP_NEXT_HOME_PAGE || 'https://next2.beyondrun.com',
}

const activeEvent = {
	production: 4,
	staging: 4,
	development: 4,
}

const ENV = process.env.REACT_APP_BUILD_ENV || 'staging'

export const ID_ALIAS_ITB = 'BNIITBUltraMarathonVirtual2020'
export const HOME_PAGE_ITB = {
	development: 'https://itb-ultra-staging.beyondrun.com',
	staging: 'https://itb-ultra-staging.beyondrun.com',
	production:
		process.env.REACT_APP_ITB_ULTRA_HOME_PAGE ||
		'https://itbultra.beyondrun.com',
	stagingNew: process.env.REACT_APP_ITB_ULTRA_HOME_PAGE,
}[ENV]
export const BASE_URL = url[ENV]
export const BASE_URL_VR = API_VR[ENV]
export const BASE_SHARE_URL = shareUrl[ENV]
export const BASE_EVENT_ID = activeEvent[ENV]
export const PUBLIC_URL = urlPublic[ENV]
export const PUBLIC_URL_NEW = urlPublicNew[ENV]
export const DUMMY_IMAGE_URL =
	'https://cdn.pixabay.com/photo/2015/02/24/15/41/dog-647528_960_720.jpg'
