import ObjectHelpers from './ObjectHelpers'

global.langObjectKey = {}
global.translate = () => {}
function grabPropTranslate(props) {
	if (props.translate) {
		global.translate = props.translate
	}
}

function mapDefaultTranslationToKey(dataTranslate, languages) {
	let keys = Object.keys(dataTranslate)

	for (let i = 0; i < keys.length; i++) {
		let key = keys[i]
		let lang = ObjectHelpers.getValueByKey(dataTranslate, `${key}.0`, null)
		if (lang) {
			global.langObjectKey[lang] = key
		}
	}
}

function getKeyByTranslation(lang) {
	return global.langObjectKey[lang]
}

function getTranslation(translation) {
	let key = getKeyByTranslation(translation)
	if (!key) {
		return null
	}
	return global.translate(key)
}

export default {
	mapDefaultTranslationToKey,
	getKeyByTranslation,
	grabPropTranslate,
	getTranslation,
}
