import React, { Component } from 'react'
import {
	BrowserRouter as Router,
	Route,
	Switch,
	Redirect,
} from 'react-router-dom'
import { LocalizeProvider } from 'react-localize-redux'
import { renderToStaticMarkup } from 'react-dom/server'
import globalTranslations from './translations/global.json'
import Loadable from './components/ReactLoadable'
import numeral from 'numeral'
import moment from 'moment'
// import localeMomentId from 'moment/locale/id'

import './Bootstrap.scss'
import './AppNew.css'
import JwtHelpers from './utils/JwtHelpers'
import TranslateHelpers from './utils/TranslateHelpers'
import { SWRConfig } from 'swr'
import ApiCall from './services/ApiCall'
import ITB from './views/Itb'
import queryString from './utils/queryString'

const Landing = Loadable({
	loader: () => import(`./views/NewPages/Landing/Landing.js`),
})

const RegisterEvent = Loadable({
	loader: () =>
		import(`./views/NewPages/RegisterEvent/RegEventNew/RegisterEventNew.js`),
})

const RegisterEventVirtualSport = Loadable({
	loader: () =>
		import(
			`./views/NewPages/RegisterEventVirtualRun/RegEventNew/RegisterEventNew.js`
		),
})

const KoleksiFoto = Loadable({
	loader: () => import(`./views/NewPages/KoleksiFoto/KoleksiFoto.js`),
})

const ListUpcomingEvent = Loadable({
	loader: () => import(`./views/NewPages/UpcomingEvent/ListUpcomingEvent`),
})

const DetailEvent = Loadable({
	loader: () => import(`./views/NewPages/Event/DetailEvent.js`),
})

const DetailRefEvent = Loadable({
	loader: () => import(`./views/NewPages/Event/DetailRefEvent.js`),
})

const About = Loadable({
	loader: () => import('./views/NewPages/About/About.js'),
})

const MyAccount = Loadable({
	loader: () => import('./views/NewPages/MyAccount/MyAccount.js'),
})

const RegisterAccount = Loadable({
	loader: () => import('./views/NewPages/RegisterAccount/RegisterAccount.js'),
})

const ResetPassword = Loadable({
	loader: () => import('./views/NewPages/ResetPassword/ResetPassword.js'),
})

const Gallery = Loadable({
	loader: () => import('./views/NewPages/KoleksiFoto/Gallery'),
})

const GalleryEvent = Loadable({
	loader: () => import('./views/NewPages/GalleryEvent/GalleryEvent'),
})

const VirtualSport = Loadable({
	loader: () => import('./views/NewPages/VirtualSport/VirtualSport'),
})

const BlogDetail = Loadable({
	loader: () => import('./views/NewPages/Blog/BlogDetail'),
})

const TermsAndConditions = Loadable({
	loader: () => import('./views/NewPages/ContentPages/TermsAndConditions'),
})

const PrivacyPolicy = Loadable({
	loader: () => import('./views/NewPages/ContentPages/PrivacyPolicy'),
})

const RegisterVirtualSport = Loadable({
	loader: () => import('./views/NewPages/RegisterVirtualSport'),
})

class App extends Component {
	constructor(props) {
		super(props)
		this.state = {}

		const format = {
			delimiters: {
				thousands: '.',
				decimal: ',',
			},
			abbreviations: {
				thousand: 'ribu',
				million: 'juta',
				billion: 'miliar',
				trillion: 'triliun',
			},
			ordinal(number) {
				return number === 1 ? 'se' : 'se'
			},
			currency: {
				symbol: 'Rp',
			},
		}

		numeral.register('locale', 'id', format)
		numeral.locale('id')
	}

	replaceURLParams = (match, urlPattern) => {
		return urlPattern
			.split('/')
			.map(param =>
				param.startsWith(':')
					? match.params[param.replace(':', '')] || ''
					: param,
			)
			.join('/')
	}

	AuthLoginRouter = (path, Component, redirectUrl) => {
		return (
			<Route
				path={path}
				key={0}
				render={props => {
					return !!JwtHelpers.getCurrentUser() ? (
						<Component {...props} />
					) : (
						window.top.location.href = '/'
						// <Redirect
						// 	to={{
						// 		pathname: redirectUrl
						// 			? this.replaceURLParams(props.match, redirectUrl)
						// 			: '/',
						// 		state: { from: props.location },
						// 	}}
						// />
					)
				}}
			/>
		)
	}

	HideWhenLoginRouter = (path, Component) => {
		return (
			<Route
				path={path}
				render={props => {
					let { search } = props.location

					const { redirect } = queryString.parse(search || '')
					if (JwtHelpers.getCurrentUser() && redirect) {
						window.location = redirect
						return <div />
					}
					return !JwtHelpers.getCurrentUser() ? (
						<Component {...props} />
					) : (
						<Redirect
							to={{
								pathname: redirect || '/',
								state: { from: props.location },
							}}
						/>
					)
				}}
			/>
		)
	}

	reRender(cp) {
		cp.setState({})
	}

	render() {
		const languages = [
			{ name: 'Indonesia', code: 'id' },
			{ name: 'English', code: 'en' },
		]
		const defaultLanguage =
			localStorage.getItem('languageCode') || languages[0].id
		moment.locale(localStorage.getItem('languageCode') || 'id')
		TranslateHelpers.mapDefaultTranslationToKey(globalTranslations, languages)

		return (
			<SWRConfig
				value={{
					dedupingInterval: 10000,
					fetcher: (...args) =>
						ApiCall.axios.get(...args).then(res => res.data),
				}}
			>
				<LocalizeProvider
					initialize={{
						languages,
						translation: globalTranslations,
						options: {
							defaultLanguage,
							renderToStaticMarkup,
						},
					}}
				>
					<ITB />
					<Router>
						<Switch>
							<Route
								exact
								path="/micro/terms-and-conditions"
								component={TermsAndConditions}
							/>
							<Route exact path="/micro/privacy-policy" component={PrivacyPolicy} />
							<Route exact path="/micro/payment/finish" component={Landing} />
							<Route exact path="/micro/payment/unfinish" component={Landing} />
							<Route exact path="/micro/payment/error" component={Landing} />
							{this.HideWhenLoginRouter('/micro/register', RegisterAccount)}
							<Route exact path="/micro/event" component={ListUpcomingEvent} />
							<Route exact path="/micro/gallery-event" component={GalleryEvent} />
							<Route
								exact
								path="/micro/virtual-event/finished"
								component={VirtualSport}
							/>
							<Route
								exact
								path="/micro/virtual-event/upcoming"
								component={VirtualSport}
							/>
							<Route
								exact
								path="/micro/virtual-event/finished/:EventId"
								component={VirtualSport}
							/>
							<Route
								exact
								path="/micro/virtual-event/upcoming/:EventId"
								component={VirtualSport}
							/>
							<Route
								exact
								path="/micro/reset-password/:token"
								component={ResetPassword}
							/>
							<Route exact path="/micro/koleksi-foto" component={KoleksiFoto} />
							<Route exact path="/micro/event/:EventId" component={DetailEvent} />
							<Route
								exact
								path="/micro/ref/event/:EventId"
								component={DetailRefEvent}
							/>
							{this.AuthLoginRouter(
								'/micro/event/pembayaran/:EventId',
								RegisterEvent,
								'/micro/event/:EventId',
							)}
							{this.AuthLoginRouter(
								'/micro/event/detail-pesanan/:EventId',
								RegisterEvent,
								'/micro/event/:EventId',
							)}
							{this.AuthLoginRouter(
								'/micro/event/data-event/:EventId',
								RegisterEvent,
								'/micro/event/:EventId',
							)}
							{this.AuthLoginRouter(
								'/micro/event/data-personal/:EventId',
								RegisterEvent,
								'/micro/event/:EventId',
							)}

							{this.AuthLoginRouter(
								'/micro/event/virtual-event/pembayaran/:EventId',
								RegisterEventVirtualSport,
								'/micro/event/virtual-event/:EventId',
							)}
							{this.AuthLoginRouter(
								'/micro/event/virtual-event/detail-pesanan/:EventId',
								RegisterEventVirtualSport,
								'/micro/event/virtual-event/:EventId',
							)}
							{this.AuthLoginRouter(
								'/micro/event/virtual-event/data-event/:EventId',
								RegisterEventVirtualSport,
								'/micro/event/virtual-event/:EventId',
							)}
							{this.AuthLoginRouter(
								'/micro/event/virtual-event/data-personal/:EventId',
								RegisterEventVirtualSport,
								'/micro/event/virtual-event/:EventId',
							)}

							{this.AuthLoginRouter('/micro/my-account', MyAccount)}
							<Route exact path="/micro" component={Landing} />
							<Route exact path="/itb" component={() => <div />} />
							<Route exact path="/micro/about" component={About} />
							<Route
								path="/micro/virtual-event/:id/register"
								component={RegisterVirtualSport}
							/>
							<Route exact path="/micro/blog/:BlogId" component={BlogDetail} />
							<Route path="/micro/search/bib/:id" component={Gallery} />
							<Redirect to="/micro" />
						</Switch>
					</Router>
				</LocalizeProvider>
			</SWRConfig>
		)
	}
}

export default App
