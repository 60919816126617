import React, { useEffect } from 'react'

const isBeyondRunDomain = url => {
	const { hostname } = new URL(url)

	return hostname.includes('beyondrun.com')
}

/**
 * @return {null}
 */
function ITB() {
	useEffect(() => {
		const t = localStorage.getItem('tokenpublic')

		const cb = event => {
			const local = 'http://localhost:3333'
			if ([local].includes(event.origin) || isBeyondRunDomain(event.origin)) {
				if (event.data === 'token') {
					event.source.postMessage(
						{
							type: 'token',
							value: t,
						},
						event.origin,
					)
				} else if (event.data && event.data.type === 'set_token') {
					const token = event.data.value
					if (token) {
						localStorage.setItem('tokenpublic', token)
					} else {
						localStorage.removeItem('tokenpublic')
					}
					event.source.postMessage(
						{
							type: 'token',
							value: token,
						},
						event.origin,
					)
				}
			}
		}

		window.addEventListener('message', cb)

		return () => {
			window.removeEventListener('message', cb)
		}
	}, [])

	return <div />
}

export default ITB
