import swal from 'sweetalert2'
import './Dialogs.css'

function showSwalLoading(text, title = '') {
	swal({title, text, allowOutsideClick: false, allowEscapeKey: false, allowEnterKey: false})
	swal.showLoading()
}
function show(opts) {
	swal(opts)
}

function showError(title, text) {
	return swal({
		title, text,
		type: 'error'
	})
}
function showWarning(title, text) {
	return swal({
		title, text,
		type: 'warning'
	})
}
function showSuccess(title, text) {
	return swal({
		title, text,
		type: 'success'
	})
}

function showMessage(title, text) {
	return swal({
		title, text,
	})
}

function closeSwal() {
	swal.close()
}

function showSwalConfirmation(settings) {
	let dataSet = settings
	if (dataSet === null) {
		dataSet = {}
	}
	swal({
		title: dataSet.title,
		text: dataSet.text,
		type: 'question',
		confirmButtonColor: dataSet.confirmButtonColor || '#d33',
		confirmButtonText: dataSet.confirmText,
		cancelButtonColor: dataSet.cancelButtonColor || '#3085d6',
		cancelButtonText: dataSet.cancelText,
		imageUrl: dataSet.imageUrl,
		imageWidth: dataSet.imageWidth || 400,
		imageHeight: dataSet.imageHeight || 200,
		showCancelButton: true,
		reverseButtons: dataSet.reverseButtons || false,
	}).then(result => {
		if (result.value) {
			if (dataSet.confirmCallback) {
				dataSet.confirmCallback()
			}
		} else if (!result.value) {
			if (dataSet.cancelCallback) {
				dataSet.cancelCallback()
			}
		}
		if (dataSet.onClose) {
			dataSet.onClose()
		}
	})
}

const DEFAULT_CONFIGS_STEP_RADIO = {
	confirmButtonText: 'Next &rarr;',
	validatorMessage: 'You need to choose something!',
	allowOutsideClick: false,
}

function showStepRadio(configs) {
	let curConfigs = Object.assign(Object.assign({}, DEFAULT_CONFIGS_STEP_RADIO), configs)
	let { confirmButtonText, queue, validatorMessage, allowOutsideClick } = curConfigs
	let inputValidator = (value, curQueue) => {
		return !value && (curQueue ? (curQueue.validatorMessage || validatorMessage) : validatorMessage)
	}
	let listQueue = queue.map((x, i) => {
		return {...x, inputValidator: (value) => inputValidator(value, x) }
	})
	swal.mixin({
		allowOutsideClick,
		input: 'radio',
		confirmButtonText,
		showCancelButton: true,
		progressSteps: queue.map((x, i) => i + 1)
	}).queue(listQueue).then((result) => {
		if (result.value) {
			curConfigs.onResult(result)

		}
	})
}

const Dialogs = {
	showMessage,
	showWarning,
	showStepRadio,
	showError,
	showSuccess,
	show,
	showSwalLoading,
	closeSwal,
	showSwalConfirmation
}

export default Dialogs
